export default [
  {
    path: "/home",
    name: "home",
    component: () => import("@/views/homePage.vue"),
  },
  {
    path: "/our-quality-and-environmental-policy",
    name: "our-quality-and-environmental-policy",
    component: () => import("@/views/qualityandenvpolicy.vue"),
  },
  {
    path: "/our-mission-and-vision",
    name: "our-mission-and-vision",
    component: () => import("@/views/ourmissionandvision.vue"),
  },
  {
    path: "/myorders/list",
    name: "myorders-list",
    component: () => import("@/views/myorders.vue"),
  },
  {
    path: "/orderbalances/list",
    name: "orderbalances-list",
    component: () => import("@/views/orderbalances.vue"),
  },
  {
    path: "/myorders/detail/:id",
    name: "myorders-detail",
    component: () => import("@/views/myorderdetail.vue"),
    meta: {
      navActiveLink: "myorders-list",
    },
  },
  {
    path: "/current-extract",
    name: "current-extract",
    component: () => import("@/views/currentextract.vue"),
  },
  {
    path: "/mycarts/list",
    name: "mycarts-list",
    component: () => import("@/views/mycarts.vue"),
  },
  {
    path: "/new-products",
    name: "new-products",
    component: () => import("@/views/newproducts.vue"),
  },
  {
    path: "/products",
    name: "products",
    component: () => import("@/views/products.vue"),
  },
  {
    path: "/discounts",
    name: "discounts",
    component: () => import("@/views/discounts.vue"),
  },
  {
    path: "/campaign-editor",
    name: "campaign-editor",
    component: () => import("@/views/campaigneditor.vue"),
  },
  {
    path: "/dealers-list",
    name: "dealers-list",
    component: () => import("@/views/dealers.vue"),
    meta: {
      pageTitle: 'Bayiler',
      breadcrumb: [
        {
          text: 'Bayiler',
        },
      ],
    },
  },
  {
    path: "/dealers/persons/:id",
    name: "dealers-persons",
    component: () => import("@/views/dealerpersons.vue"),
    meta: {
      pageTitle: 'Kişiler',
      breadcrumb: [
        {
          text: 'Bayiler',
          to: { name: 'dealers-list' }
        },
        {
          text: 'Kişiler',
          active:true
        },
      ],
    },
  },
  {
    path: "/campaign-products",
    name: "campaign-products",
    component: () => import("@/views/campaignproducts.vue"),
  },
  {
    path: "/campaigns",
    name: "campaigns",
    component: () => import("@/views/campaigns.vue"),
  },
  {
    path: "/redirect-official",
    name: "redirect-official",
    component: () => import("@/views/redofficial.vue"),
    meta: {
      layout: "full",
    },
  },
  {
    path: '/login',
    name: 'auth-login',
    component: () => import('@/views/login.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
];
